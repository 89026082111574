import axios from 'axios';
import { TextDecoder } from 'text-encoding';
import { FileType, Region, ResourceState } from '@shapeci/types';
import jwt from 'jwt-decode';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

const areSessionsEqual = (a, b) => (a === null || a === void 0 ? void 0 : a.sessionId) === (b === null || b === void 0 ? void 0 : b.sessionId) && (a === null || a === void 0 ? void 0 : a.userId) === (b === null || b === void 0 ? void 0 : b.userId);

const GITEA_DEFAULT_BRANCH_NAME = 'master';
const GITEA_DEFAULT_BRANCH_NAME_ALIAS = 'Production';

const EDITOR_WIDTH = '720px';
const COMMENT_GUTTER = 300;

var HttpStatusCode;
(function (HttpStatusCode) {
    HttpStatusCode[HttpStatusCode["CONTINUE"] = 100] = "CONTINUE";
    HttpStatusCode[HttpStatusCode["SWITCHING_PROTOCOLS"] = 101] = "SWITCHING_PROTOCOLS";
    HttpStatusCode[HttpStatusCode["PROCESSING"] = 102] = "PROCESSING";
    HttpStatusCode[HttpStatusCode["OK"] = 200] = "OK";
    HttpStatusCode[HttpStatusCode["CREATED"] = 201] = "CREATED";
    HttpStatusCode[HttpStatusCode["ACCEPTED"] = 202] = "ACCEPTED";
    HttpStatusCode[HttpStatusCode["NON_AUTHORITATIVE_INFORMATION"] = 203] = "NON_AUTHORITATIVE_INFORMATION";
    HttpStatusCode[HttpStatusCode["NO_CONTENT"] = 204] = "NO_CONTENT";
    HttpStatusCode[HttpStatusCode["RESET_CONTENT"] = 205] = "RESET_CONTENT";
    HttpStatusCode[HttpStatusCode["PARTIAL_CONTENT"] = 206] = "PARTIAL_CONTENT";
    HttpStatusCode[HttpStatusCode["MULTI_STATUS"] = 207] = "MULTI_STATUS";
    HttpStatusCode[HttpStatusCode["ALREADY_REPORTED"] = 208] = "ALREADY_REPORTED";
    HttpStatusCode[HttpStatusCode["IM_USED"] = 226] = "IM_USED";
    HttpStatusCode[HttpStatusCode["MULTIPLE_CHOICES"] = 300] = "MULTIPLE_CHOICES";
    HttpStatusCode[HttpStatusCode["MOVED_PERMANENTLY"] = 301] = "MOVED_PERMANENTLY";
    HttpStatusCode[HttpStatusCode["FOUND"] = 302] = "FOUND";
    HttpStatusCode[HttpStatusCode["SEE_OTHER"] = 303] = "SEE_OTHER";
    HttpStatusCode[HttpStatusCode["NOT_MODIFIED"] = 304] = "NOT_MODIFIED";
    HttpStatusCode[HttpStatusCode["USE_PROXY"] = 305] = "USE_PROXY";
    HttpStatusCode[HttpStatusCode["SWITCH_PROXY"] = 306] = "SWITCH_PROXY";
    HttpStatusCode[HttpStatusCode["TEMPORARY_REDIRECT"] = 307] = "TEMPORARY_REDIRECT";
    HttpStatusCode[HttpStatusCode["PERMANENT_REDIRECT"] = 308] = "PERMANENT_REDIRECT";
    HttpStatusCode[HttpStatusCode["BAD_REQUEST"] = 400] = "BAD_REQUEST";
    HttpStatusCode[HttpStatusCode["UNAUTHORIZED"] = 401] = "UNAUTHORIZED";
    HttpStatusCode[HttpStatusCode["PAYMENT_REQUIRED"] = 402] = "PAYMENT_REQUIRED";
    HttpStatusCode[HttpStatusCode["FORBIDDEN"] = 403] = "FORBIDDEN";
    HttpStatusCode[HttpStatusCode["NOT_FOUND"] = 404] = "NOT_FOUND";
    HttpStatusCode[HttpStatusCode["METHOD_NOT_ALLOWED"] = 405] = "METHOD_NOT_ALLOWED";
    HttpStatusCode[HttpStatusCode["NOT_ACCEPTABLE"] = 406] = "NOT_ACCEPTABLE";
    HttpStatusCode[HttpStatusCode["PROXY_AUTHENTICATION_REQUIRED"] = 407] = "PROXY_AUTHENTICATION_REQUIRED";
    HttpStatusCode[HttpStatusCode["REQUEST_TIMEOUT"] = 408] = "REQUEST_TIMEOUT";
    HttpStatusCode[HttpStatusCode["CONFLICT"] = 409] = "CONFLICT";
    HttpStatusCode[HttpStatusCode["GONE"] = 410] = "GONE";
    HttpStatusCode[HttpStatusCode["LENGTH_REQUIRED"] = 411] = "LENGTH_REQUIRED";
    HttpStatusCode[HttpStatusCode["PRECONDITION_FAILED"] = 412] = "PRECONDITION_FAILED";
    HttpStatusCode[HttpStatusCode["PAYLOAD_TOO_LARGE"] = 413] = "PAYLOAD_TOO_LARGE";
    HttpStatusCode[HttpStatusCode["URI_TOO_LONG"] = 414] = "URI_TOO_LONG";
    HttpStatusCode[HttpStatusCode["UNSUPPORTED_MEDIA_TYPE"] = 415] = "UNSUPPORTED_MEDIA_TYPE";
    HttpStatusCode[HttpStatusCode["RANGE_NOT_SATISFIABLE"] = 416] = "RANGE_NOT_SATISFIABLE";
    HttpStatusCode[HttpStatusCode["EXPECTATION_FAILED"] = 417] = "EXPECTATION_FAILED";
    HttpStatusCode[HttpStatusCode["I_AM_A_TEAPOT"] = 418] = "I_AM_A_TEAPOT";
    HttpStatusCode[HttpStatusCode["MISDIRECTED_REQUEST"] = 421] = "MISDIRECTED_REQUEST";
    HttpStatusCode[HttpStatusCode["UNPROCESSABLE_ENTITY"] = 422] = "UNPROCESSABLE_ENTITY";
    HttpStatusCode[HttpStatusCode["LOCKED"] = 423] = "LOCKED";
    HttpStatusCode[HttpStatusCode["FAILED_DEPENDENCY"] = 424] = "FAILED_DEPENDENCY";
    HttpStatusCode[HttpStatusCode["UPGRADE_REQUIRED"] = 426] = "UPGRADE_REQUIRED";
    HttpStatusCode[HttpStatusCode["PRECONDITION_REQUIRED"] = 428] = "PRECONDITION_REQUIRED";
    HttpStatusCode[HttpStatusCode["TOO_MANY_REQUESTS"] = 429] = "TOO_MANY_REQUESTS";
    HttpStatusCode[HttpStatusCode["REQUEST_HEADER_FIELDS_TOO_LARGE"] = 431] = "REQUEST_HEADER_FIELDS_TOO_LARGE";
    HttpStatusCode[HttpStatusCode["UNAVAILABLE_FOR_LEGAL_REASONS"] = 451] = "UNAVAILABLE_FOR_LEGAL_REASONS";
    HttpStatusCode[HttpStatusCode["INTERNAL_SERVER_ERROR"] = 500] = "INTERNAL_SERVER_ERROR";
    HttpStatusCode[HttpStatusCode["NOT_IMPLEMENTED"] = 501] = "NOT_IMPLEMENTED";
    HttpStatusCode[HttpStatusCode["BAD_GATEWAY"] = 502] = "BAD_GATEWAY";
    HttpStatusCode[HttpStatusCode["SERVICE_UNAVAILABLE"] = 503] = "SERVICE_UNAVAILABLE";
    HttpStatusCode[HttpStatusCode["GATEWAY_TIMEOUT"] = 504] = "GATEWAY_TIMEOUT";
    HttpStatusCode[HttpStatusCode["HTTP_VERSION_NOT_SUPPORTED"] = 505] = "HTTP_VERSION_NOT_SUPPORTED";
    HttpStatusCode[HttpStatusCode["VARIANT_ALSO_NEGOTIATES"] = 506] = "VARIANT_ALSO_NEGOTIATES";
    HttpStatusCode[HttpStatusCode["INSUFFICIENT_STORAGE"] = 507] = "INSUFFICIENT_STORAGE";
    HttpStatusCode[HttpStatusCode["LOOP_DETECTED"] = 508] = "LOOP_DETECTED";
    HttpStatusCode[HttpStatusCode["NOT_EXTENDED"] = 510] = "NOT_EXTENDED";
    HttpStatusCode[HttpStatusCode["NETWORK_AUTHENTICATION_REQUIRED"] = 511] = "NETWORK_AUTHENTICATION_REQUIRED";
})(HttpStatusCode || (HttpStatusCode = {}));

/* eslint-disable @typescript-eslint/no-explicit-any */
const getErrorMessage = (error, defaultMessage = 'An error occurred') => {
    var _a, _b, _c, _d, _e, _f;
    if (typeof error === 'string')
        return error;
    // Return generic error
    if (!axios.isAxiosError(error))
        return (_a = error === null || error === void 0 ? void 0 : error.message) !== null && _a !== void 0 ? _a : defaultMessage;
    // Return top level axios error
    let resp = (_b = error.response) === null || _b === void 0 ? void 0 : _b.data;
    if (!resp)
        return getAxiosErrorMessage(error);
    // Auth0 error
    if (typeof (resp === null || resp === void 0 ? void 0 : resp.error_description) === 'string')
        return resp.error_description;
    // Try to get error message from the server
    if (resp.byteLength) {
        const dec = new TextDecoder('utf-8');
        const str = dec.decode(resp);
        try {
            resp = JSON.parse(str);
        }
        catch (e) {
            return str;
        }
    }
    return ((_f = (_e = (_c = resp === null || resp === void 0 ? void 0 : resp.message) !== null && _c !== void 0 ? _c : (_d = error.response) === null || _d === void 0 ? void 0 : _d.message) !== null && _e !== void 0 ? _e : error.message) !== null && _f !== void 0 ? _f : defaultMessage);
};
const getAxiosErrorMessage = (error) => {
    switch (error.status) {
        case '401':
            return 'Unauthorized';
        case '403':
            return 'Unauthorized';
        case '404':
            return 'Not found';
        case '408':
            return 'Request timed out';
        case '413':
            return 'Request is too large';
        case '429':
            return 'There are too many requests. Try again later';
        case '502':
            return 'Our servers are down';
        case '504':
            return 'Our servers timed out';
        default:
            return error.message;
    }
};

class BaseError extends Error {
    constructor(type, message) {
        super(message);
        this.type = type;
    }
    toString() {
        return `Error ${this.type}: ${getErrorMessage(this)}\nStack:${this.stack}\nCause:${this.cause}`;
    }
}

const ERR_AUTH0 = 'ErrAuth0';
class Auth0Error extends BaseError {
    constructor(title, message) {
        super(ERR_AUTH0, message);
        this.title = title;
    }
}
const isAuth0Error = (maybeErr) => maybeErr instanceof Auth0Error;

class RequestError extends BaseError {
    constructor(type, message, code) {
        super(type, message);
        this.type = type;
        this.code = code;
    }
}

const ERR_BAD_REQUEST = 'ErrBadRequest';
class BadRequestError extends RequestError {
    constructor(message) {
        super(ERR_BAD_REQUEST, message, HttpStatusCode.BAD_REQUEST);
    }
}

// Reference: https://stackoverflow.com/questions/12093748/how-do-i-check-for-valid-git-branch-names
const BRANCH_REGEX = new RegExp(/^(?!.*\/)(?!.*\.\.)(?!.*\/\/)(?!.*@\{)(?!.*\\)[^\x00-\x1f\x7f ~\^\:\?\*\[]+(?<!\.lock)(?<!\.)$/);
const MAX_BRANCH_NAME_LENGTH = 30;
const FORBIDDEN_NEW_BRANCH_NAMES = ['master', 'main'];
const isBranchnameValid = (branchname) => {
    const match = branchname.match(BRANCH_REGEX);
    return !!(match === null || match === void 0 ? void 0 : match.length);
};
const isNewBranchnameValid = (branchname) => {
    if (FORBIDDEN_NEW_BRANCH_NAMES.includes(branchname.toLowerCase()))
        return false;
    if (branchname.length > MAX_BRANCH_NAME_LENGTH)
        return false;
    return isBranchnameValid(branchname);
};

const getBranchValidationError = (branchName) => {
    if (branchName.length > MAX_BRANCH_NAME_LENGTH) {
        return `Workstream Name Invalid: Name exceeds ${MAX_BRANCH_NAME_LENGTH} characters`;
    }
    if (FORBIDDEN_NEW_BRANCH_NAMES.includes(branchName.toLowerCase())) {
        return `Workstream Name Invalid: name ${branchName} is forbidden`;
    }
    if (branchName.includes('/')) {
        return 'Workstream Name Invalid: Contains forward slash ("/")';
    }
    if (branchName.includes('..')) {
        return 'Workstream Name Invalid: Contains double dots ("..")';
    }
    if (branchName.includes('//')) {
        return 'Workstream Name Invalid: Contains double forward slashes ("//")';
    }
    if (branchName.includes('@{')) {
        return 'Workstream Name Invalid: Contains "@" followed by "{"';
    }
    if (branchName.includes('\\')) {
        return 'Workstream Name Invalid: Contains backslash ("")';
    }
    if (branchName.endsWith('.lock')) {
        return 'Workstream Name Invalid: Ends with ".lock"';
    }
    if (branchName.endsWith('.')) {
        return 'Workstream Name Invalid: Ends with period (".")';
    }
    return 'Workstream Name Invalid: Contains invalid characters or format';
};

const CONTROL_CHARACTERS = /\x00-\x1f\x7f/;
const CONTROL_CHARACTER_REGEX = new RegExp(`[${CONTROL_CHARACTERS.source}]`);
// Matches everything that's NOT invalid
const VALID_FILE_CHARACTER_REGEX = new RegExp(/[^\\\/:\*"\?<>|\x00-\x1f\x7f]/);
// Can't end in period
const VALID_FILE_TERMINATION_CHARACTER_REGEX = new RegExp(/[^ \.\\\/:*"?<>|\x00-\x1f\x7f]/);
// Regex matches forbidden name followed by ".", EOL, or space
const FORBIDDEN_FILENAMES_REGEX = /(?:COM[0-9]|CON|LPT[0-9]|NUL|PRN|AUX|com[0-9]|con|lpt[0-9]|nul|prn|aux)(\.|$)|\s|[\.]{2,}/;
// Reference: https://learn.microsoft.com/en-us/windows/win32/fileio/naming-a-file
const FILE_REGEX = new RegExp(`(?!${FORBIDDEN_FILENAMES_REGEX.source})${VALID_FILE_CHARACTER_REGEX.source}{1,253}${VALID_FILE_TERMINATION_CHARACTER_REGEX.source}`);
const ENTIRE_INPUT_FILE_REGEX = new RegExp(`^${FILE_REGEX.source}$`);
const isFilenameValid = (filename) => {
    if (filename.length === 1)
        return isTerminationCharacterValid(filename);
    const match = filename.match(ENTIRE_INPUT_FILE_REGEX);
    return (match === null || match === void 0 ? void 0 : match.length) === 2;
};
const isTerminationCharacterValid = (char) => {
    if (char.length !== 1)
        throw new Error(`${char} isn't a character`);
    const match = char.match(VALID_FILE_TERMINATION_CHARACTER_REGEX);
    return (match === null || match === void 0 ? void 0 : match.length) === 1;
};

const DEFAULT_FILENAME_VALIDATION_ERROR = 'File Name Invalid';
const INVALID_CHARACTERS = {
    '\\': 'back slash',
    '/': 'forward slash',
    '*': 'asterix',
    '"': 'double quote',
    '?': 'question mark',
    '<': 'less than sign',
    '>': 'greater than sign',
    '|': 'pipe symbol',
    ':': 'colon',
};
const INVALID_TERMINATION_CHARACTERS = {
    '.': 'period',
    ' ': 'space',
};
const generateForbiddenNameError = (forbiddenName) => `${DEFAULT_FILENAME_VALIDATION_ERROR}: '${forbiddenName}' cannot be used as a file name`;
const generateControlOrNullByteError = () => `${DEFAULT_FILENAME_VALIDATION_ERROR}: using a control character or null byte is not allowed`;
const generateInvalidTerminationCharacterError = (invalidCharacter) => `${DEFAULT_FILENAME_VALIDATION_ERROR}: ending with a ${INVALID_TERMINATION_CHARACTERS[invalidCharacter]} ('${invalidCharacter}') is not allowed`;
const generateInvalidCharacterError = (invalidCharacter) => `${DEFAULT_FILENAME_VALIDATION_ERROR}: using a ${INVALID_CHARACTERS[invalidCharacter]} ('${invalidCharacter}') is not allowed`;
const getIncludesCharacterError = (input) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const invalidCharacter of Object.keys(INVALID_CHARACTERS)) {
        if (input.includes(invalidCharacter))
            return generateInvalidCharacterError(invalidCharacter);
    }
    return undefined;
};
const getTerminationCharactersError = (input) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const invalidTerminationCharacter of Object.keys(INVALID_TERMINATION_CHARACTERS)) {
        if (input.endsWith(invalidTerminationCharacter))
            return generateInvalidTerminationCharacterError(invalidTerminationCharacter);
    }
    return undefined;
};
const getForbiddenFileNamesError = (input) => {
    const match = input.match(FORBIDDEN_FILENAMES_REGEX);
    if (!match || !match.length)
        return undefined;
    const strippedInput = match[0].replace(/[.\s]/g, '');
    if (!strippedInput.length)
        return undefined;
    return generateForbiddenNameError(strippedInput);
};
function getControlOrNullCharacterError(input) {
    const match = input.match(CONTROL_CHARACTER_REGEX);
    if (!match || !match.length)
        return undefined;
    return generateControlOrNullByteError();
}
const getFileNameValidationError = (filename) => {
    let error = getControlOrNullCharacterError(filename);
    if (error)
        return error;
    error = getTerminationCharactersError(filename);
    if (error)
        return error;
    error = getIncludesCharacterError(filename);
    if (error)
        return error;
    error = getForbiddenFileNamesError(filename);
    if (error)
        return error;
    return DEFAULT_FILENAME_VALIDATION_ERROR;
};

const ERR_INTERNAL_SERVER = 'ErrInternalServer';
class InternalServerError extends RequestError {
    constructor(message) {
        super(ERR_INTERNAL_SERVER, message, HttpStatusCode.INTERNAL_SERVER_ERROR);
    }
}

const ERR_LEGACY_ACCOUNT = 'ErrLegacyAccount';
class LegacyAccountError extends RequestError {
    constructor(message) {
        super(ERR_LEGACY_ACCOUNT, message, HttpStatusCode.CONFLICT);
    }
}
/* eslint-disable @typescript-eslint/no-explicit-any */
const isLegacyAccountError = (err) => {
    var _a, _b, _c, _d;
    if (err instanceof LegacyAccountError)
        return true;
    if (!axios.isAxiosError(err))
        return false;
    const sampleErr = new LegacyAccountError('');
    if (((_a = err.response) === null || _a === void 0 ? void 0 : _a.status) !== sampleErr.code)
        return false;
    return ((_d = (_c = (_b = err.response) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.result) === null || _d === void 0 ? void 0 : _d.type) === ERR_LEGACY_ACCOUNT;
};
/* eslint-enable @typescript-eslint/no-explicit-any */

const ERR_NO_LINKED_ACCOUNT = 'ErrNoLinkedAccount';
class NoLinkedAccountError extends RequestError {
    constructor(message) {
        super(ERR_NO_LINKED_ACCOUNT, message, HttpStatusCode.UNAUTHORIZED);
    }
}
/* eslint-disable @typescript-eslint/no-explicit-any */
const isNoLinkedAccountError = (err) => {
    var _a, _b, _c, _d;
    if (err instanceof NoLinkedAccountError)
        return true;
    if (!axios.isAxiosError(err))
        return false;
    const sampleErr = new NoLinkedAccountError('');
    if (((_a = err.response) === null || _a === void 0 ? void 0 : _a.status) !== sampleErr.code)
        return false;
    return ((_d = (_c = (_b = err.response) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.result) === null || _d === void 0 ? void 0 : _d.type) === ERR_NO_LINKED_ACCOUNT;
};
/* eslint-enable @typescript-eslint/no-explicit-any */

const ERR_NOT_AUTHENTICATED = 'ErrNotAuthenticated';
class NotAuthenticatedError extends RequestError {
    constructor(message) {
        super(ERR_NOT_AUTHENTICATED, message, HttpStatusCode.UNAUTHORIZED);
    }
}

const ERR_ACCESS_DENIED = 'access_denied';
const DEFAULT_ERR = {
    title: 'Error Signing In',
    message: 'Please try again later',
};
const formatAuth0Error = (error) => {
    if (!error)
        return DEFAULT_ERR;
    if (error.error === ERR_ACCESS_DENIED) {
        return {
            title: 'Access Denied',
            message: 'You are not a part of this organization. Get invited by a member and use the sign up link in your email.',
        };
    }
    return {
        title: error.error || DEFAULT_ERR.title,
        message: error.error_description || DEFAULT_ERR.message,
    };
};

const formatBranchName = (name) => {
    if (name === GITEA_DEFAULT_BRANCH_NAME)
        return GITEA_DEFAULT_BRANCH_NAME_ALIAS;
    return name;
};

const truncateString = (str, maxLength) => {
    if (str.length <= maxLength)
        return str;
    return `${str.substring(0, maxLength)}...`;
};

/* eslint-disable  no-restricted-syntax */
const MAX_LINE_LENGTH = 80;
const formatCommitMessage = (message) => {
    const lines = message.split('\n').map((l) => l.trim());
    for (const line of lines) {
        if (line.length > 0)
            return truncateString(line, MAX_LINE_LENGTH);
    }
    return 'Commit Message';
};

const formatName = (firstName, lastName, maxCharacters = 12) => {
    if (!firstName && !lastName)
        return `loading...`;
    if (firstName && !lastName)
        return firstName.slice(0, maxCharacters);
    if (!firstName && lastName)
        return lastName.slice(0, maxCharacters);
    if (!firstName || !lastName)
        throw new Error("Invariant violation: This case is impossible, but typescript isn't recognizing that");
    if (firstName.length + lastName.length <= maxCharacters)
        return `${firstName} ${lastName}`;
    if (firstName.length + 1 <= maxCharacters)
        return `${firstName} ${lastName === null || lastName === void 0 ? void 0 : lastName.charAt(0)}.`;
    return firstName.slice(0, maxCharacters);
};

function getBranchRootFolderId(branch) {
    const lastCommit = branch.commits.find((c) => c.commit === branch.headCommit);
    if (!lastCommit)
        throw new Error(`Could not find head commit, ${branch.headCommit}, on branch, ${branch.name}`);
    return lastCommit.root;
}

const includesIgnoreCase = (array, search) => {
    const lower = array.map((it) => it.toLowerCase());
    return lower.includes(search.toLowerCase());
};

/* eslint-disable no-restricted-syntax */
const getFileExtensionOrNull = (filename) => {
    try {
        return getFileExtension(filename);
    }
    catch (error) {
        return null;
    }
};
const getFileExtension = (filename) => {
    const ext = filename.split('.').pop();
    for (const [_, v] of Object.entries(FileType)) {
        if (ext.toLowerCase() === v.toLowerCase()) {
            return v;
        }
    }
    throw new Error(`Invalid file type: ${ext}`);
};
const isValidFileType = (maybeExt) => 
// eslint-disable-next-line @typescript-eslint/no-explicit-any
maybeExt !== undefined && includesIgnoreCase(Object.values(FileType), maybeExt);

/*
 * The following helpers allow for the creation of sharable
 * URLs handled fully client-side.
 *
 * Users can share `/documents/${ANY_TITLE}-${TRIMMED_DOCUMENT_ID} and
 * the correct document should load where the ID is a trimmed UUIDv4
 *
 */
const ROUTE_DELIMITER = '-';
function getTrimmedId(fullUUID) {
    return fullUUID.split('-').join('');
}
function getSanitizedTitle(title) {
    const substitutedTitle = title.replaceAll(/[^a-zA-Z\d]+/g, ROUTE_DELIMITER);
    const segments = substitutedTitle.split('-').filter((str) => str.length > 0);
    return segments.join(ROUTE_DELIMITER);
}
function getSharableRoute(fullId, rawTitle) {
    const trimmedId = getTrimmedId(fullId);
    if (!rawTitle) {
        return trimmedId;
    }
    const sanitizedTitle = getSanitizedTitle(rawTitle);
    return `${sanitizedTitle}${ROUTE_DELIMITER}${trimmedId}`;
}

const getUserLastViewedTeamId = (userTeams, lastViewedTeam) => {
    if (!userTeams.length)
        throw new Error('User is not a part of any teams');
    if (!lastViewedTeam || !userTeams.map((t) => t.id).includes(lastViewedTeam))
        return userTeams[0].id;
    return lastViewedTeam;
};

/**
 * This is only used by the desktop app, but it needs to be in both the preload script
 * and the render process, so this is the easiest way to have the code shared.
 */
var IPCRenderSendChannel;
(function (IPCRenderSendChannel) {
    IPCRenderSendChannel["OPEN_BROWSER"] = "openBrowserWindow";
    IPCRenderSendChannel["LOG"] = "log";
    IPCRenderSendChannel["LOGOUT"] = "logout";
    IPCRenderSendChannel["READY_FOR_CLOSE"] = "readyForClose";
})(IPCRenderSendChannel || (IPCRenderSendChannel = {}));
var IPCRenderReceiveChannel;
(function (IPCRenderReceiveChannel) {
    IPCRenderReceiveChannel["ON_FOCUS"] = "onFocus";
    IPCRenderReceiveChannel["AUTH_UPDATED"] = "authUpdated";
    IPCRenderReceiveChannel["UPDATE_AVAIlABLE"] = "update_available";
    IPCRenderReceiveChannel["UPDATE_DOWNLOADED"] = "udpate_downloaded";
    IPCRenderReceiveChannel["APP_QUIT"] = "app_quit";
    // Git command progress
    IPCRenderReceiveChannel["ON_GIT_CMD_PROGRESS"] = "onGitCmdProgress";
})(IPCRenderReceiveChannel || (IPCRenderReceiveChannel = {}));
var IPCRenderBidirectionalChannel;
(function (IPCRenderBidirectionalChannel) {
    IPCRenderBidirectionalChannel["WRITE"] = "write";
    IPCRenderBidirectionalChannel["COPY"] = "copy";
    IPCRenderBidirectionalChannel["COMMAND_EXISTS"] = "commandExists";
    IPCRenderBidirectionalChannel["GET_APP_VERSION"] = "getAppVersion";
    IPCRenderBidirectionalChannel["GET_PATH"] = "getPath";
    IPCRenderBidirectionalChannel["GET_ROOT_PATH"] = "getRootPath";
    IPCRenderBidirectionalChannel["READ"] = "read";
    IPCRenderBidirectionalChannel["MKDIR"] = "mkdr";
    IPCRenderBidirectionalChannel["EXISTS"] = "exists";
    IPCRenderBidirectionalChannel["OPEN_EXPLORER"] = "openExplorer";
    IPCRenderBidirectionalChannel["GET_AUTH"] = "getAuth";
    // Git commands
    IPCRenderBidirectionalChannel["SPAWN_AND_COMPLETE"] = "spawnAndComplete";
    IPCRenderBidirectionalChannel["GIT_EXECUTE"] = "gitExecute";
    // Fs commands
    IPCRenderBidirectionalChannel["FS_ACCESS"] = "fsAccess";
    IPCRenderBidirectionalChannel["FS_READ_FILE"] = "fsReadFile";
    IPCRenderBidirectionalChannel["FS_STAT"] = "fsStat";
    // Pathname
    IPCRenderBidirectionalChannel["PATH_NORMALIZE"] = "normalize";
    IPCRenderBidirectionalChannel["PATH_RESOLVE"] = "resolve";
    IPCRenderBidirectionalChannel["PATH_JOIN"] = "join";
    IPCRenderBidirectionalChannel["PATH_BASENAME"] = "basename";
    IPCRenderBidirectionalChannel["PATH_EXTNAME"] = "extname";
    // Misc commands
    IPCRenderBidirectionalChannel["GET_OS_INFO"] = "getPlatformInfo";
    IPCRenderBidirectionalChannel["IS_PACKAGED"] = "isPackaged";
})(IPCRenderBidirectionalChannel || (IPCRenderBidirectionalChannel = {}));
const ANY_CHANNEL = [
    ...Object.values(IPCRenderSendChannel),
    ...Object.values(IPCRenderReceiveChannel),
    ...Object.values(IPCRenderBidirectionalChannel),
];

const isAssemblyPath = (path) => {
    const ext = getFileExtensionOrNull(path);
    return isAssemblyType(ext);
};
const isAssemblyType = (ext) => {
    switch (ext === null || ext === void 0 ? void 0 : ext.toLowerCase()) {
        case FileType.CATIA_PRODUCT:
        case FileType.CREO_ASM:
        case FileType.INVENTOR_ASM:
        case FileType.SOLID_EDGE_ASM:
        case FileType.SOLID_EDGE_PSM:
        case FileType.SOLID_WORKS_ASM:
            return true;
        default:
            return false;
    }
};

const isCadexLicenseActive = (ext) => {
    switch (ext) {
        case FileType.OBJ:
        case FileType.STL:
        case FileType.CREO_PRT:
        case FileType.CREO_ASM:
        case FileType.SOLID_WORKS_ASM:
        case FileType.SOLID_WORKS_PRT:
            return true;
        default:
            return false;
    }
};

const isGitPathValid = (filepath) => {
    if (filepath.length === 0 || filepath[0] === '/')
        return false;
    const components = filepath.split('/');
    if (components.length === 0)
        return false;
    return components.every(isFilenameValid);
};

const isModelPath = (path) => {
    const ext = getFileExtensionOrNull(path);
    return isModelType(ext);
};
const isModelType = (ext) => {
    if (isAssemblyType(ext))
        return true;
    switch (ext === null || ext === void 0 ? void 0 : ext.toLowerCase()) {
        case FileType.BREP:
        case FileType.SOLID_WORKS_ASM:
        case FileType.SOLID_WORKS_PRT:
        case FileType.CATIA_PART:
        case FileType.CATIA_PRODUCT:
        case FileType.COLLADA:
        case FileType.CREO_PRT:
        case FileType.CREO_ASM:
        case FileType.CDXFB:
        case FileType.GLTF:
        case FileType.IFC:
        case FileType.IGES:
        case FileType.IGS:
        case FileType.INVENTOR_PRT:
        case FileType.INVENTOR_ASM:
        case FileType.JT:
        case FileType.NX:
        case FileType.OBJ:
        case FileType.PRC:
        case FileType.RHINO:
        case FileType.SOLID_EDGE_PAR:
        case FileType.SOLID_EDGE_PSM:
        case FileType.SOLID_EDGE_ASM:
        case FileType.STEP:
        case FileType.STP:
        case FileType.STL:
        case FileType.U3D:
        case FileType.USD:
        case FileType.USDA:
        case FileType.USDC:
        case FileType.USDZ:
        case FileType.VRML:
        case FileType.X3D:
        case FileType.XKT:
            return true;
        default:
            return false;
    }
};

const REGION_KEY = 'region';
const getRegion = (storage) => {
    const region = storage.getItem(REGION_KEY);
    if (isValidRegion(region))
        return region;
    return Region.US_1;
};
const setRegion = (storage, region) => {
    storage.setItem(REGION_KEY, region);
};
const getAvatarBucketUri = (storage) => {
    const region = getRegion(storage);
    switch (region) {
        case Region.US_1:
            return process.env.REACT_APP_S3_AVATAR_BUCKET_URI_US_1;
        case Region.US_GOV_1:
            return process.env.REACT_APP_S3_AVATAR_BUCKET_URI_US_GOV_1;
        default:
            throw new Error(`Invalid region ${region}`);
    }
};
const getServerURL = (storage) => {
    const region = getRegion(storage);
    switch (region) {
        case Region.US_1:
            return process.env.REACT_APP_API_URL_US_1;
        case Region.US_GOV_1:
            return process.env.REACT_APP_API_URL_US_GOV_1;
        default:
            throw new Error(`Invalid region ${region}`);
    }
};
const isValidRegion = (maybeRegion) => Object.values(Region).includes(maybeRegion);

/* eslint-disable @typescript-eslint/no-explicit-any */
const parseAccessToken = (jwtToken) => {
    var _a, _b, _c, _d;
    const token = jwt(jwtToken);
    const maybeOrgId = (_a = token === null || token === void 0 ? void 0 : token['https://app.shape.ci/claims/org']) === null || _a === void 0 ? void 0 : _a.id;
    const orgId = typeof maybeOrgId === 'string' ? maybeOrgId : undefined;
    const maybeOrgName = (_b = token === null || token === void 0 ? void 0 : token['https://app.shape.ci/claims/org']) === null || _b === void 0 ? void 0 : _b.display_name;
    const org = typeof maybeOrgName === 'string' ? maybeOrgName : undefined;
    const maybeRegion = (_d = (_c = token === null || token === void 0 ? void 0 : token['https://app.shape.ci/claims/org']) === null || _c === void 0 ? void 0 : _c.metadata) === null || _d === void 0 ? void 0 : _d.region;
    const region = isValidRegion(maybeRegion) ? maybeRegion : Region.US_1;
    const maybeExp = token === null || token === void 0 ? void 0 : token.exp;
    const exp = typeof maybeExp === 'number' ? maybeExp : undefined;
    if (!exp)
        throw new Error(`Token must have expiration time`);
    const maybeSub = token === null || token === void 0 ? void 0 : token.sub;
    const sub = typeof maybeSub === 'string' ? maybeSub : undefined;
    if (!sub)
        throw new Error(`Token must have sub`);
    const maybeEmail = token === null || token === void 0 ? void 0 : token['https://app.shape.ci/claims/email'];
    const email = typeof maybeEmail === 'string' ? maybeEmail : undefined;
    if (!email)
        throw new Error(`Token must have email`);
    return {
        email,
        sub,
        org,
        orgId,
        region,
        exp,
    };
};

// If a token will expire in one minute, consider it expired
const TOKEN_MIN_TTL = 60;
const isTokenExpired = (token) => {
    if (!token)
        return true;
    try {
        const { exp } = parseAccessToken(token);
        const cur = Math.floor(Date.now() / 1000);
        return cur + TOKEN_MIN_TTL >= exp;
    }
    catch (error) {
        return true;
    }
};

const PATTERN = /^https?:\/\//i;
const isURLAbsolute = (url) => PATTERN.test(url);

const isNullish = (maybeNullish) => maybeNullish === undefined || maybeNullish === null;
const isNotNullish = (maybeNullish) => !isNullish(maybeNullish);

function parseNumber(maybeInt, defaultNumber) {
    /* eslint-enable no-redeclare */
    if (!maybeInt)
        return defaultNumber !== null && defaultNumber !== void 0 ? defaultNumber : null;
    if (typeof maybeInt === 'number')
        return maybeInt;
    try {
        const parsedInt = parseInt(maybeInt, 10);
        // eslint-disable-next-line no-restricted-globals
        return !isNaN(parsedInt) ? parsedInt : null;
    }
    catch (error) {
        return defaultNumber !== null && defaultNumber !== void 0 ? defaultNumber : null;
    }
}

const separateBranchCommitsFromMaster = (masterCommits, branchCommits) => {
    const minLength = Math.min(masterCommits.length, branchCommits.length);
    let divergenceIndex = minLength;
    for (let i = 0; i < minLength; i++) {
        const masterCommitHash = getHashFromCommit(masterCommits[i]);
        const branchCommitHash = getHashFromCommit(branchCommits[i]);
        if (masterCommitHash !== branchCommitHash) {
            divergenceIndex = i;
            break;
        }
    }
    return branchCommits.slice(divergenceIndex);
};
const getHashFromCommit = (commit) => {
    if ('sha' in commit)
        return commit.sha;
    if ('commit' in commit)
        return commit.commit;
    if ('id' in commit)
        return commit.id;
    return null;
};

const sleep = (ms) => new Promise((resolve) => {
    setTimeout(resolve, ms);
});

/**
 * This can be used to statically assert that a variable is of type never.
 *
 * This is particularly useful for switch statements on enums.
 * If there is a case for every item in the enum, the default case will have the variable as type 'never'.
 * If a new value is added to the enum, we probably want the dev to add a case to the switch statement.
 * So adding, assertNever to the default case creates a build error in this situation, forcing the dev
 * to add a case for it.
 *
 * Ex:
 * enum option {
 *     A = 'a'
 *     B = 'b'
 * }
 *
 * function processOption(opt: option) {
 *     switch (opt) {
 *         case option.A:
 *             // DO WORK
 *             break;
 *         case option.B:
 *             // DO WORK
 *             break;
 *         default:
 *             // We already handled all cases, this should never be reached, so we assert never.
 *             // If the option enum is changed, this will cause a build time error... much better
 *             // than having a runtime error
 *             assertNever(opt)
 *     }
 * }
 */
const assertNever = (a) => true;

dayjs.extend(relativeTime);
function parseDate(date) {
    if (date instanceof Date)
        return date;
    return new Date(Date.parse(date));
}
/**
 * Gets time relative to the current time
 * @param {string | date} date
 *
 * @example getTimeAgo('2021-01-01') // in 2022 this outputs "1 year ago"
 */
function formatTimeAgo(date) {
    return dayjs(parseDate(date)).fromNow();
}
/**
 * Gets the current date in a readable format
 * @param {string | date} date
 *
 * @example getDate('2001-05-06') // outputs "May 6, 2001"
 */
function formatDate(date) {
    return dayjs(parseDate(date)).format('MMMM DD, YYYY');
}
/**
 * Gets the current date in a readable format with time of day to the minute
 * @param {string | date} date
 *
 * @example getDateWithTime('2023-01-01 23:00:00') // outputs "May 6, 2001, 11:00 PM"
 */
function formatDateWithTime(date) {
    return dayjs(parseDate(date)).format('MMMM DD, YYYY, h:mm A');
}

/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Adds polling to an axios instance. Use this to prevent timeouts on long running requests. This middleware
 * makes the following assumptions about the server that this is to be used with:
 *     1. The server will immediately return a 202 on the initial request.
 *     2. The 202 response will contain a "location" header that specifies a URL to poll status from
 *     3. The polling location will return an object that contains "status", that is "Succeeded" or "Failed"
 *     4. If the polling location returns "Succeeded", it will also include "resourceLocation" in the response
 *        which indicates the location of the fulfilled resource
 * @param axios An axios instance used by createAxios
 * @param pollIntervalMs The interval in milliseconds to poll the status endpoint
 */
const withPolling = (axios, pollIntervalMs = 2000) => {
    if (!axios)
        return axios;
    // Axios interceptor to handle responses that need to be polled
    axios.interceptors.response.use(async (response) => {
        var _a;
        // Use the 202 response code as an indicator that polling is needed
        if (response.status !== 202)
            return response;
        const pollingLocation = response.headers.location;
        if (typeof pollingLocation !== 'string')
            throw new Error('Expected a location heading from a polling response');
        // Retrieve the initial operation status
        let pollingResponse = await axios.get(pollingLocation, {
            headers: response.config.headers,
        });
        // Call progress
        const progressConfig = response.config;
        (_a = progressConfig.onProgress) === null || _a === void 0 ? void 0 : _a.call(progressConfig, pollingResponse.data);
        // Wait for the op
        pollingResponse = await waitForResource(axios, progressConfig, pollingLocation, pollIntervalMs);
        if (pollingResponse.data.status === ResourceState.FAILED)
            throw new Error(getErrorMessage(pollingResponse));
        const { resourceLocation } = pollingResponse.data;
        if (typeof resourceLocation !== 'string')
            throw new Error('Expected a resource location from a successful polling operation');
        // Once operation succeeded, return response from final resource location
        return axios.get(resourceLocation, {
            responseType: response.config.responseType || 'arraybuffer',
            headers: response.config.headers,
            maxContentLength: Infinity,
            maxBodyLength: Infinity,
        });
    });
    return axios;
};
const waitForResource = (axios, config, pollingLocation, intervalMs) => new Promise((res, rej) => {
    const thisTimer = setInterval(async () => {
        var _a;
        let response;
        try {
            response = await axios.get(pollingLocation);
            (_a = config.onProgress) === null || _a === void 0 ? void 0 : _a.call(config, response.data);
        }
        catch (error) {
            clearInterval(thisTimer);
            rej(error);
            return;
        }
        if ([ResourceState.SUCCEEDED, ResourceState.FAILED].includes(response.data.status)) {
            clearInterval(thisTimer);
            res(response);
        }
    }, intervalMs);
});

export { ANY_CHANNEL, Auth0Error, BRANCH_REGEX, BadRequestError, BaseError, COMMENT_GUTTER, CONTROL_CHARACTERS, CONTROL_CHARACTER_REGEX, DEFAULT_FILENAME_VALIDATION_ERROR, EDITOR_WIDTH, ENTIRE_INPUT_FILE_REGEX, FILE_REGEX, FORBIDDEN_FILENAMES_REGEX, FORBIDDEN_NEW_BRANCH_NAMES, GITEA_DEFAULT_BRANCH_NAME, GITEA_DEFAULT_BRANCH_NAME_ALIAS, HttpStatusCode, IPCRenderBidirectionalChannel, IPCRenderReceiveChannel, IPCRenderSendChannel, InternalServerError, LegacyAccountError, MAX_BRANCH_NAME_LENGTH, NoLinkedAccountError, NotAuthenticatedError, RequestError, areSessionsEqual, assertNever, formatAuth0Error, formatBranchName, formatCommitMessage, formatDate, formatDateWithTime, formatName, formatTimeAgo, generateControlOrNullByteError, generateForbiddenNameError, generateInvalidCharacterError, generateInvalidTerminationCharacterError, getAvatarBucketUri, getBranchRootFolderId, getBranchValidationError, getErrorMessage, getFileExtension, getFileExtensionOrNull, getFileNameValidationError, getRegion, getServerURL, getSharableRoute, getUserLastViewedTeamId, includesIgnoreCase, isAssemblyPath, isAssemblyType, isAuth0Error, isBranchnameValid, isCadexLicenseActive, isFilenameValid, isGitPathValid, isLegacyAccountError, isModelPath, isModelType, isNewBranchnameValid, isNoLinkedAccountError, isNotNullish, isNullish, isTokenExpired, isURLAbsolute, isValidFileType, isValidRegion, parseAccessToken, parseNumber, separateBranchCommitsFromMaster, setRegion, sleep, truncateString, withPolling };
