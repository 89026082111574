import {
    ClientReviewDocument,
    DocumentReviewStatus,
    Mergability,
    MergabilityReason,
    MergeStatus,
} from '@shapeci/types'

export const getMergeMessage = (
    isReviewApproved: boolean,
    status?: MergeStatus,
    documentMetadata?: ClientReviewDocument
) => {
    // if loading or unknown, don't show error
    if (!status || status.mergability === Mergability.UNKNOWN) return null

    // if mergable, don't show error
    if (status.mergability === Mergability.MERGABLE) return null

    if (!isReviewApproved) return 'More reviews Required to Merge'

    if (documentMetadata?.status === DocumentReviewStatus.MERGED) return 'Merged'

    if (documentMetadata?.status === DocumentReviewStatus.CLOSED) return 'Merge Request Closed'

    if (status.mergability === Mergability.UNMERGABLE) {
        if (status.reason === MergabilityReason.CONFLICTS)
            return 'Conflicts Exist. Make sure workstream on Connector is synced.'

        if (status.reason === MergabilityReason.NO_DIFF) return 'No Changes to Merge'
    }

    if (status.mergability === Mergability.INDETERMINATE)
        return 'Cannot Merge. Make sure workstream on Connector is synced.'

    return 'Cannot Merge'
}
