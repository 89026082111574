import { ClientFolder, Folder, Model, MongoModelType, WebAccessibleModel } from '@shapeci/types'
import { Space, Table, TableMenuAction } from '@shapeci/ui'
import { GITEA_DEFAULT_BRANCH_NAME } from '@shapeci/utils'
import { CloudUpload, File, Trash } from '@styled-icons/boxicons-regular'
import { Folder as FolderIcon } from '@styled-icons/boxicons-solid'
import { FC, useMemo } from 'react'
import styled from 'styled-components'

import { getTimeAgo } from '../../utils/formatTime'

interface DirectoryFolder extends ClientFolder {
    isEditable: boolean
}

interface DirectoryModel extends Model {
    isEditable: boolean
}

type DirectoryItem = DirectoryFolder | DirectoryModel

interface RepoDirectoryProps {
    folder: ClientFolder | null
    onFolderCreate(newFolder: Folder): void
    onEmptyFolderDelete: (row: any) => void
    onModelCreate(newModel: WebAccessibleModel): void
    onModelUpdate(updatedModel: WebAccessibleModel): void
    onRowClick(entry: ClientFolder | Model): void
    isRepoEditable?: boolean
    branchName?: string
    isLoading: boolean
    isFolderDeleteLoading: boolean
    isError: boolean
    setIsUploadModelModalOpen: (isOpen: boolean) => void
    setIsCreateFolderModalOpen: (isOpen: boolean) => void
    fromModelModalSelector: boolean
}

const getTableData = (
    clientFolder: ClientFolder | null,
    isRepoEditable: boolean
): DirectoryItem[] => {
    if (!clientFolder) {
        return []
    }

    const folders =
        clientFolder.directories.map((childFolder) => ({
            ...childFolder,
            isEditable: isRepoEditable,
        })) || []

    const content =
        clientFolder.content.map((model) => ({
            ...model,
            isEditable: isRepoEditable,
        })) || []

    return [...folders, ...content] as DirectoryItem[]
}

const TitleWrapper = styled(Space)`
    svg {
        width: 1.75em;
        height: 1.75em;
        fill: ${({ theme }) => theme.colors.grey600};
    }
`

const RepoDirectory: FC<RepoDirectoryProps> = ({
    folder,
    isError,
    // TODO implement onModelUpdate
    onRowClick,
    onEmptyFolderDelete,
    isRepoEditable = true,
    isLoading,
    branchName,
    setIsCreateFolderModalOpen,
    setIsUploadModelModalOpen,
    fromModelModalSelector,
}) => {
    const data = useMemo(() => getTableData(folder, isRepoEditable), [folder, isRepoEditable])
    const canMutateFolder = !fromModelModalSelector && branchName === GITEA_DEFAULT_BRANCH_NAME
    const menuActions: TableMenuAction<DirectoryItem>[] | undefined = canMutateFolder
        ? [
              {
                  label: 'Delete',
                  id: 'remove',
                  onClick: (row) => onEmptyFolderDelete(row),
                  icon: <Trash />,
                  intent: 'danger',
              },
              {
                  label: 'Upload Files',
                  id: 'upload-modal',
                  icon: <CloudUpload />,
                  global: true,
                  onClick: () => setIsUploadModelModalOpen(true),
              },
              {
                  label: 'Create Folder',
                  id: 'create-folder',
                  icon: <FolderIcon />,
                  global: true,
                  onClick: () => setIsCreateFolderModalOpen(true),
              },
          ]
        : undefined

    return (
        <Table
            emptyState={{
                message: 'Folder is empty',
                description: canMutateFolder
                    ? 'Create a folder or upload a model'
                    : 'Publish folders and models via the Connector App',
            }}
            errorState={{
                message: 'An error occurred',
            }}
            isError={isError}
            isLoading={isLoading}
            sortable
            columns={[
                {
                    header: 'Title',
                    id: 'title',
                    cell: (cell) => {
                        let icon = <File />
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        let title = cell.row.original.name
                        if (cell.row.original.type === MongoModelType.FOLDER) {
                            icon = <FolderIcon />
                            title = cell.row.original.title
                        }

                        return (
                            <Space align="center" size={2}>
                                <TitleWrapper align="center">
                                    {icon} {title}
                                </TitleWrapper>
                            </Space>
                        )
                    },
                    width: 1,
                },
                {
                    header: 'Last Updated',
                    id: 'meta.lastUpdated',
                    cell: (cell) => <p>{getTimeAgo(cell.getValue() as string | Date)}</p>,
                    width: '125px',
                },
            ]}
            data={data}
            onRowDoubleClick={onRowClick}
            menuActions={menuActions}
        />
    )
}

RepoDirectory.defaultProps = {
    isRepoEditable: true,
}

export default RepoDirectory
