/* eslint-disable no-use-before-define */
import { useAuth0 } from '@auth0/auth0-react'
import {
    BlockId,
    BlockType,
    ClientFolder,
    ClientKnowledgeDocument,
    ClientReviewDocument,
    ClientThread,
    CommentAddedPayload,
    CommentDeletedPayload,
    CommentId,
    CommentUpdatedPayload,
    DocumentCollaborator,
    DocumentReviewStatus,
    ModelCommit,
    ModelId,
    ModelPathData,
    MongoModelType,
    Operation,
    OperationType,
    RepositoryId,
    RestorePoint,
    SetSelectionOperation,
    ThreadDeletedPayload,
    ThreadId,
    TitleChangedPayload,
    UserDisconnectedPayload,
    UserJoinedDocumentPayload,
    WebSocketErrorPayload,
    WebSocketErrors,
    WebSocketEvents,
    WebSocketLoadedPayload,
    WebSocketNewEditorPayload,
    WebSocketRestorePayload,
    WebSocketTransactionPayload,
} from '@shapeci/types'
import { Button, LIGHT_THEME, Portal } from '@shapeci/ui'
import { areSessionsEqual, COMMENT_GUTTER, EDITOR_WIDTH, getSharableRoute } from '@shapeci/utils'
import { History } from '@styled-icons/boxicons-regular'
import debounce from 'lodash.debounce'
import omit from 'lodash.omit'
import throttle from 'lodash.throttle'
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { DndProvider, DropTargetMonitor, useDrop } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { Helmet } from 'react-helmet'
import { useLocation, useParams } from 'react-router-dom'
import { Descendant } from 'slate'
import { Socket } from 'socket.io-client'
import styled, { css } from 'styled-components'
import { v4 as uuidv4 } from 'uuid'

import { cache } from '../caches'
import CommentButton from '../components/Comments/CommentButton'
import ThreadManager from '../components/Comments/ThreadManager'
import { AnyThread, NewThread } from '../components/Comments/types'
import { createNewThread, isNewThread } from '../components/Comments/utils'
import { BannerStack, useBannerStore } from '../components/Editor/Banners'
import {
    HoverPayload,
    insertBlock,
    insertImage,
    insertModelViewer,
    OpenWizardOptions,
    selectNodeStart,
    setNodes,
    ShapeEditor,
    ShapeEditorType,
    ShapeEditorValue,
    TransactionObserver,
} from '../components/Editor/core'
import Drawer from '../components/Editor/Drawer'
import HistoryOverlay from '../components/Editor/History'
import SelectImageModal from '../components/Editor/image.modal'
import TeamMenu from '../components/Editor/Menu/TeamMenu'
import SelectModelModal from '../components/Editor/model.modal'
import { DocumentMode, ModeSelect, TitleContainer, TitleInput } from '../components/Editor/ui'
import { SIDE_BAR_WIDTH } from '../components/Layout/constants'
import ShareButton from '../components/MenuBar/ShareButton'
import StarButton from '../components/MenuBar/StarButton'
import { RequestDialog } from '../components/Reviews/RequestDialog'
import { BLOCK_DRAG_KEY } from '../constants/dragKeys'
import { useApi } from '../hooks/useApi'
import { useAppNavigate } from '../hooks/useAppNavigate'
import { useAuth } from '../hooks/useAuth'
import useDocumentStore from '../hooks/useDocumentStore'
import useMenuBarStore from '../hooks/useMenuBarStore'
import useWindowSize from '../hooks/useWindowSize'
import { modelStore } from '../store/model.store'
import { getCrumbs } from '../utils/crumb'
import { useErrorNotification } from '../utils/dispatchNotifications'
import { getPathFromCursor } from '../utils/dnd'
import { getIdFromSharableRoute } from '../utils/documents'
import { isWizardBlock } from '../utils/editor'
import { isReviewDocument } from '../utils/types'
import { createWebSocket } from '../utils/ws/websocket'

const NOT_YOUR_TEAM_BANNER_ID = 'not-your-team-banner'
const OUTDATED_VERSION_BANNER_ID = 'outdated-version-banner'

const EditZoneContainer = styled.div`
    box-sizing: border-box;
    width: 100%;
    height: calc(100% - 40px);
    margin-top: 40px;
    padding-top: ${({ theme }) => theme.getSpacing(6)};

    overflow-y: auto;
`
const ElementsContainer = styled.div`
    max-width: 900px;
    position: relative;
`

const EditorInner = styled.div`
    box-sizing: border-box;
    position: relative;
    height: 100%;
    flex-grow: 1;
    font-weight: 400;
    width: ${EDITOR_WIDTH};
    line-height: 1.5;
    margin: 0 auto;
    transform: translateX(-${COMMENT_GUTTER / 3}px);
`

const EditorWrapper = styled.div<{
    $activeEditor: DocumentCollaborator | null
    $isViewing: boolean
    $withDrawer: boolean
}>`
    display: flex;
    height: 100%;
    padding: 0;
    box-sizing: border-box;
    position: relative;
    flex-direction: column;

    display: grid;
    transition: grid-template-columns 0.375s ease-in-out;
    grid-template-columns: 1fr ${SIDE_BAR_WIDTH};

    ${({ $withDrawer }) =>
        !$withDrawer &&
        css`
            grid-template-columns: 1fr 0px;
        `}

    grid-template-rows: 1fr;

    ${({ $isViewing, theme }) =>
        $isViewing && `*::selection { background: ${theme.colors.info100} !important; }`}

    .shape__editor-cursor-label {
        ${({ $activeEditor }) =>
            $activeEditor
                ? css`
                      :before {
                          white-space: nowrap;
                          content: '${$activeEditor.firstName} ${$activeEditor.lastName}';
                      }
                  `
                : // if theres no active editor, hide the cursor label
                  'display: none;'}
    }

    .slate-Draggable-dropLine {
        background: ${({ theme }) => theme.colors.primary500};
        height: 2px;

        &::before {
            content: '';
            position: absolute;
            left: -0.4em;
            top: -0.25em;
            height: 0.4em;
            width: 0.4em;
            border-radius: 50%;
            background: ${({ theme }) => theme.colors.grey100};
            border: 2px solid ${({ theme }) => theme.colors.primary500};
        }
    }
`

const DropBar = styled.div`
    position: absolute;
    top: 0;
    height: 2px;
    width: 100%;
    display: none;
    background: ${({ theme }) => theme.colors.primary500};
    transform: translateY(0);
    transition: transform 0.1s cubic-bezier(0.4, 0, 0.2, 1);

    ::before {
        content: '';
        position: absolute;
        left: -0.4em;
        top: -0.25em;
        height: 0.4em;
        width: 0.4em;
        border-radius: 50%;
        background: ${({ theme }) => theme.colors.grey100};
        border: 2px solid ${({ theme }) => theme.colors.primary500};
    }
`

const transactionObserver = new TransactionObserver()

const Editor: FC = () => (
    <div id="editor-dnd-wrap" style={{ minHeight: '100%', height: '100%' }}>
        <DndProvider backend={HTML5Backend}>
            <EditorContent />
        </DndProvider>
    </div>
)

const EditorContent: FC = () => {
    const authContext = useAuth0()
    const api = useApi()

    // document
    const [title, setTitle] = useState<string>('')
    const [documentId, setDocumentId] = useState<string | null>(null)
    const [isStarred, setIsStarred] = useState<boolean>(false)
    const [documentMetadata, setDocumentMetadata] = useState<
        ClientReviewDocument | ClientKnowledgeDocument
    >()
    const [selectedRestorePoint, setSelectedRestorePoint] = useState<RestorePoint | null>(null)
    const [folder, setFolder] = useState<ClientFolder | null>(null)
    const [isPublicAccess, setIsPublicAccess] = useState<boolean>(false)

    // TODO: remove this in favor of props of better matches
    const location = useLocation()
    const isPublicRoute = location.pathname.includes('/public/')

    const isPublicView = isPublicRoute || isPublicAccess

    // stores
    const menuBar = useMenuBarStore((state) => omit(state, ['value']))
    const documentStore = useDocumentStore()
    const dispatchError = useErrorNotification()
    const { data: user } = cache.useUser()
    const { isAuthenticated } = useAuth()

    const userId = useMemo(() => user?.id, [user])
    const [isChangingMode, setIsChangingMode] = useState(false)

    // editor
    const [socket, setSocket] = useState<Socket | null>(null)
    const [editorValue, setEditorValue] = useState<ShapeEditorValue | null>(null)
    const [hoveredBlock, setHoveredBlock] = useState<HoverPayload | null>(null)

    const [activeEditor, setActiveEditor] = useState<DocumentCollaborator | null>(null)
    const lastDraggedElemId = useRef<string | null>(null)
    const isMovingTimeout = useRef<number>(-1)
    const hasSnappedToBlock = useRef<boolean>(false)

    // drawer
    const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(true)
    const [isHistoryOpen, setIsHistoryOpen] = useState<boolean>(false)

    // comments
    const [threads, setThreads] = useState<Array<AnyThread>>([])
    const [openThreadId, setOpenThreadId] = useState<string | null>(null)
    const [editingCommentId, setEditingCommentId] = useState<string | null>(null)
    const [nodeIdOrder, setNodeIdOrder] = useState<string[]>([])

    const editorRef = useRef<ShapeEditorType | null>(null)
    const dropBarRef = useRef<HTMLDivElement | null>(null)

    // Modals
    const [wizardOptions, setWizardOptions] = useState<OpenWizardOptions>({})
    const [isModelModalOpen, setIsModelModalOpen] = useState<boolean>(false)
    const [isImageModalOpen, setIsImageModalOpen] = useState<boolean>(false)
    const [isNewDocumentModalOpen, setIsNewDocumentModalOpen] = useState<boolean>(false)

    // Banners
    const { addBanner, removeBanner } = useBannerStore()

    // DOM
    const { width } = useWindowSize()

    // routing
    const navigate = useAppNavigate()
    const { documentSlug } = useParams()
    if (!documentSlug) {
        navigate('/folders')
    }

    const [cursorPosition, setCursorPosition] = useState<{
        x: number
        y: number
    }>({
        x: 0,
        y: 0,
    })

    const onDropBlock = (item: { type: BlockType }) => {
        // Editor is still loading
        if (!editorValue?.nodes) return

        const editor = editorRef?.current
        if (!editor) throw new Error('Expected editor to be non-null')

        if (dropBarRef.current) {
            dropBarRef.current.style.display = 'none'
        }

        // eslint-disable-next-line prefer-const
        let path = getPathFromCursor(cursorPosition)

        // if (!path.length && !isHoveringBelow.current) return
        if (!path.length) return

        // TODO re-implement bottom/last block drop
        // if (isHoveringBelow.current) {
        //     path = [editorValue.nodes.length - 1]
        // }

        const newBlockPath = [path[0] + 1]

        if (isWizardBlock(item.type)) {
            onOpenWizard(item.type, { isNew: true })
        } else {
            insertBlock(editor as any, '', item.type, newBlockPath)
            selectNodeStart(editor as any, newBlockPath.concat(0))
        }
    }

    const onHoverBlock = useCallback(
        throttle((monitor: DropTargetMonitor) => {
            if (!dropBarRef.current) return

            const newCursorPosition = monitor.getClientOffset()
            if (newCursorPosition === null) return

            // TODO use approximation of cursor movement to determine if it's moving
            if (newCursorPosition.y === cursorPosition.y) return

            clearTimeout(isMovingTimeout.current)
            isMovingTimeout.current = -1

            const cursorDelta = newCursorPosition.y - cursorPosition.y

            // update cursor position
            setCursorPosition(newCursorPosition)
            const { x, y } = newCursorPosition

            // get parent to position relative to it
            const parent = document.getElementById('editor-inner')
            const parentWidth = parent?.offsetWidth ? `${parent.offsetWidth}px` : 'auto'
            const parentTop = parent?.getBoundingClientRect()?.top ?? 0

            // show drop bar
            dropBarRef.current.style.display = 'block'

            // TODO merge "last-block" logic with "hovering-below" logic
            // get the element under the cursor
            // const elem = document.elementFromPoint(x, y)?.closest('.slate-Draggable')

            // if (!elem) {
            //     // get all draggable (i.e. full-width) elements
            //     const draggableElems = document.querySelectorAll('.slate-Draggable')

            //     // get the last draggable element
            //     const lastDraggableElem = draggableElems[draggableElems.length - 1]
            //     if (!lastDraggableElem) {
            //         return
            //     }

            //     const { top: lastDraggableTop, height: lastDraggableHeight } =
            //         lastDraggableElem.getBoundingClientRect()

            //     // get position after last block
            //     const position = lastDraggableTop + lastDraggableHeight - parentTop

            //     if (y < position) {
            //         // if the mouse is above the last block, don't show the drop bar
            //         dropBarRef.current.style.display = 'none'
            //         isHoveringBelow.current = false
            //         return
            //     }

            //     isHoveringBelow.current = true

            //     // otherwise the user is below the last block, so show the drop bar
            //     dropBarRef.current.style.display = 'block'
            //     dropBarRef.current.style.transform = `translateY(calc(${position}px))`
            //     return
            // }

            // isHoveringBelow.current = false
            const elem = document.elementFromPoint(x, y)?.closest('.slate-Draggable')

            const deltaLargerThanHeight = Math.abs(cursorDelta) > (elem?.clientHeight ?? 0)

            if (
                elem?.id !== lastDraggedElemId.current ||
                hasSnappedToBlock.current !== true ||
                deltaLargerThanHeight
            ) {
                if (dropBarRef.current && elem) {
                    const { top, height } = elem.getBoundingClientRect()
                    dropBarRef.current.style.transform = `translateY(calc(${
                        top + height - parentTop
                    }px))`
                }
                hasSnappedToBlock.current = true
                return
            }

            // show the drop bar and position it
            dropBarRef.current.style.width = parentWidth
            dropBarRef.current.style.transform = `translateY(calc(${y - parentTop}px))`
            dropBarRef.current.style.display = 'block'

            hasSnappedToBlock.current = false
            lastDraggedElemId.current = elem?.id ?? null

            if (!elem) {
                return
            }

            const { top, height } = elem.getBoundingClientRect()

            isMovingTimeout.current = window.setTimeout(() => {
                if (dropBarRef.current) {
                    dropBarRef.current.style.transform = `translateY(calc(${
                        top + height - parentTop
                    }px))`
                }
                hasSnappedToBlock.current = true
            }, 75)
        }, 50),
        [cursorPosition]
    )

    const [, dropRef] = useDrop({
        accept: BLOCK_DRAG_KEY,
        drop: onDropBlock,
        collect: (monitor: DropTargetMonitor) => ({
            isOver: monitor.isOver(),
        }),
        hover: (_: any, monitor: DropTargetMonitor) => {
            onHoverBlock(monitor)
        },
    })

    // comments
    const handleOpenThread = (threadId: string | null) => {
        const willDrawerOverlap = width ? width < 1700 : false

        // TODO - we might not need this anymore
        if (threadId !== null && willDrawerOverlap) {
            setIsDrawerOpen(false)
        }

        setOpenThreadId(threadId)
    }

    const addNewThread = (referencedBlock: BlockId) => {
        if (threads.some((thread) => isNewThread(thread))) {
            setThreads((prev) =>
                prev.map((thread) => {
                    if (isNewThread(thread)) {
                        return {
                            ...thread,
                            referencedBlock,
                        }
                    }
                    return thread
                })
            )
            return
        }

        setThreads((prev) => [...prev, createNewThread(referencedBlock)])
    }

    const cancelAddNewThread = () => {
        setThreads((prev) => prev.filter((thread) => !isNewThread(thread)))
    }

    const submitComment = async (content: string, thread: NewThread | ClientThread) => {
        if (!socket) return

        let threadId: string | undefined

        if (!isNewThread(thread)) {
            threadId = thread.id
        }

        socket.emit(WebSocketEvents.ADD_COMMENT, {
            type: WebSocketEvents.ADD_COMMENT,
            comment: {
                type: MongoModelType.COMMENT,
                id: uuidv4(),
                content,
            },
            referencedBlock: threadId ? undefined : thread.referencedBlock,
            threadId,
            isNewThread: isNewThread(thread),
        })
    }

    const editComment = async (threadId: ThreadId, commentId: CommentId, content: string) => {
        if (!socket) return

        socket.emit(WebSocketEvents.UPDATE_COMMENT, {
            type: WebSocketEvents.UPDATE_COMMENT,
            threadId,
            commentId,
            comment: {
                content,
            },
        })

        // clear editing comment on save
        setEditingCommentId(null)
    }

    const deleteComment = async (threadId: ThreadId, commentId: CommentId) => {
        if (!socket) return

        socket.emit(WebSocketEvents.DELETE_COMMENT, {
            type: WebSocketEvents.DELETE_COMMENT,
            threadId,
            commentId,
        })
    }

    const deleteThread = async (threadId: ThreadId) => {
        if (!socket) return

        socket.emit(WebSocketEvents.DELETE_THREAD, {
            type: WebSocketEvents.DELETE_THREAD,
            threadId,
        })
    }

    useEffect(() => {
        documentStore.setLocalSessionId(uuidv4())
        menuBar.setFullWidth(true)

        return () => {
            menuBar.setValue(null)
            menuBar.setFullWidth(false)
        }
    }, [])

    useEffect(() => {
        // figure out it we should forward to the private version of the document
    }, [userId, isPublicRoute])

    useEffect(() => {
        if (!documentMetadata) return

        if (
            isReviewDocument(documentMetadata) &&
            documentMetadata.status === DocumentReviewStatus.OPEN &&
            documentMetadata.isOutdated
        ) {
            const isNewestDocument =
                documentMetadata.id === documentMetadata!.latestDocument!.latestDocumentId

            const message = isNewestDocument
                ? 'This document is outdated. Create a new document with the latest changes on this workstream'
                : "This document is outdated. There's a review document on this workstream with newer changes"

            const buttonText = isNewestDocument ? 'Create Document' : 'View Latest Document'

            addBanner({
                id: OUTDATED_VERSION_BANNER_ID,
                message,
                intent: 'warning',
                right: (
                    <Button
                        onClick={() => {
                            if (isNewestDocument) {
                                setIsNewDocumentModalOpen(true)
                            } else {
                                const url = `/documents/${getSharableRoute(
                                    documentMetadata.latestDocument!.latestDocumentId,
                                    documentMetadata.title
                                )}`

                                navigate(url)
                            }
                        }}
                    >
                        {buttonText}
                    </Button>
                ),
            })
        } else {
            removeBanner(OUTDATED_VERSION_BANNER_ID)
        }

        let crumbs = [
            {
                label: title,
                value: '.',
            },
        ]
        if (!isPublicAccess && !selectedRestorePoint) {
            crumbs = getCrumbs(documentMetadata, title, folder)
        }

        menuBar.setPath(crumbs)
        if (isPublicAccess) {
            // if its a public route then append the buttons after load
            menuBar.setPostActions([<ShareButton isDocumentPublic={documentMetadata?.isPublic} />])
        }

        if (!isPublicAccess && !selectedRestorePoint) {
            let docLocation: string
            if (isReviewDocument(documentMetadata)) {
                docLocation = `/projects/${getSharableRoute(documentMetadata.repoId)}/reviews/${
                    documentMetadata.branch
                }`
            } else {
                docLocation = `/folders/${getSharableRoute(documentMetadata.parent)}`
            }

            menuBar.setPrePath([<TeamMenu location={docLocation} isPublic={isPublicRoute} />])
        }

        menuBar.setOnCrumbClick((v) => navigate(v))
        menuBar.setBottomless(false)
    }, [documentMetadata, title, folder, isPublicAccess, isPublicRoute, selectedRestorePoint])

    const onDocumentModeChanged = (mode: DocumentMode) => {
        if (mode === DocumentMode.EDITING) return enterEditMode()
        if (mode === DocumentMode.REVIEWING) return enterViewingMode()
        throw new Error(`Invalid mode: ${mode}`)
    }

    // effect for showing other team banner
    useEffect(() => {
        if (isPublicView && !isPublicAccess) {
            addBanner({
                id: NOT_YOUR_TEAM_BANNER_ID,
                message: "You're viewing a document from another team",
                intent: 'info',
            })
        } else {
            removeBanner(NOT_YOUR_TEAM_BANNER_ID)
        }
    }, [isPublicView, isPublicAccess])

    // effect for setting the general menu bar buttons
    useEffect(() => {
        if (isPublicView) {
            // if document isn't loaded yet on public mode don't show anything
            return
        }

        // normally authenticated users can see all the buttons
        menuBar.setPreActions([<StarButton isStarred={isStarred} dataType={1} />])
        menuBar.setPostActions([
            <Button prepend={<History />} intent="muted" onClick={() => setIsHistoryOpen(true)}>
                History
            </Button>,
            <ShareButton
                isDocumentPublic={documentMetadata?.isPublic}
                setIsDocumentPublic={setIsPublic}
            />,
        ])
    }, [
        isStarred,
        isChangingMode,
        documentStore,
        selectedRestorePoint,
        socket,
        isPublicRoute,
        isPublicAccess,
        documentMetadata?.isPublic,
    ])

    // effect for getting the document slug/id
    useEffect(() => {
        const maybeDocumentId = getIdFromSharableRoute(documentSlug ?? '')
        setDocumentId(maybeDocumentId)
    }, [documentSlug])

    useEffect(() => {
        if (!selectedRestorePoint) {
            return
        }

        // create a new transaction to clear the selection
        transactionObserver.emitTransaction([
            {
                cachedVersion: 0,
                transactionId: uuidv4(),
                type: OperationType.SET_SELECTION,
                properties: null,
                newProperties: null,
            } as SetSelectionOperation,
        ])
    }, [selectedRestorePoint])

    const saveTitle = useMemo(
        () =>
            debounce((newTitle: string) => {
                if (!socket) return

                socket.emit(WebSocketEvents.SET_TITLE, {
                    type: WebSocketEvents.SET_TITLE,
                    title: newTitle,
                })
            }, 1000),
        [socket]
    )

    const saveTitleEvent = (newTitle: string) => {
        if (isPreviewMode()) return
        setTitle(newTitle)
        saveTitle(newTitle)
    }

    const setIsPublic = async (isPublic: boolean) => {
        if (!documentId) {
            return
        }

        const setPublicMetadata = (maybeIsPublic: boolean) =>
            setDocumentMetadata((prev) => {
                if (!prev) {
                    throw new Error('Document metadata is undefined')
                }

                return {
                    ...prev,
                    isPublic: maybeIsPublic,
                }
            })

        try {
            setPublicMetadata(isPublic)

            let req
            if (isPublic) {
                req = api.setDocumentPublic(documentId)
            } else {
                req = api.setDocumentPrivate(documentId)
            }

            const res = await req

            if (!res.success) {
                throw new Error(res.message)
            }
        } catch (e) {
            setPublicMetadata(!isPublic)
            dispatchError(`Couldn't set document ${isPublic ? 'public' : 'private'}. Try again.`)
        }
    }

    const restoreDocument = (restorePointId: string) => {
        if (!socket) return

        const payload: WebSocketRestorePayload = {
            type: WebSocketEvents.RESTORE,
            restorePointId,
        }
        socket.emit(WebSocketEvents.RESTORE, payload)
        setSelectedRestorePoint(null)

        // wait a a few ms for restore event to clear
        // TODO: Handle event results via Socket responses
        setTimeout(() => setIsHistoryOpen(false), 600)
    }

    const updateThreads = (updatedThread: ClientThread) => {
        setThreads((prev) => {
            let idToUpdate = updatedThread.id

            if (prev.findIndex((thread) => thread.id === updatedThread.id) === -1) {
                idToUpdate = 'NEW_THREAD'
            }
            const next = prev.map((thread) => {
                if (thread.id === idToUpdate) {
                    return updatedThread
                }
                return thread
            })

            // TODO -- consider removing this failsafe, it could end up being hacky/buggy
            if (!next.some((thread) => thread.id === updatedThread.id)) {
                next.push(updatedThread)
            }

            return next
        })
    }

    const removeThread = (threadId: ThreadId) => {
        setThreads((prev) => prev.filter((thread) => thread.id !== threadId))
    }

    const initSocket = (s: Socket) => {
        s.on(WebSocketEvents.ERROR, (payload: WebSocketErrorPayload) => {
            console.error('Websocket Error Payload ', s.id, payload)
            setIsChangingMode(false)

            switch (payload.type) {
                case WebSocketErrors.DOCUMENT_NOT_FOUND:
                    s.close()
                    if (!isPublicRoute) {
                        navigate('/folders')
                    }
                    break
                case WebSocketErrors.PAYLOAD_MALFORMED:
                case WebSocketErrors.DOCUMENT_NOT_LOADED:
                    loadDocument(s)
                    break
                case WebSocketErrors.NOT_EDITOR:
                    reloadDocument()
                    break
                default:
            }
        })

        s.on(WebSocketEvents.DOCUMENT_LOADED, (payload: WebSocketLoadedPayload) => {
            setIsChangingMode(false)
            setIsPublicAccess(payload.isPublicAccess)
            setEditorValue({
                nodes: payload.editorContent as any,
                undoHistory: payload.undoHistory,
                cachedVersion: 0,
            })

            documentStore.setEditorSession(payload.activeEditorSession)
            setTitle(payload.title)

            const maybeActiveEditor = payload.activeUsers.find(
                (u) =>
                    u.id === payload.activeEditorSession?.userId &&
                    u.sessionId === payload.activeEditorSession?.sessionId
            )

            setActiveEditor(maybeActiveEditor ?? null)

            setNodeIdOrder(getIdOrderFromDescendents(payload.editorContent))
            menuBar.setUsers(payload.activeUsers)
        })

        s.on(WebSocketEvents.NEW_EDITOR_ASSIGNED, (payload: WebSocketNewEditorPayload) => {
            const thisSession = {
                userId,
                sessionId: documentStore.value.localSessionId,
            }

            // If promoted to editor, reload the document to get in the latest changes
            if (isViewer() && areSessionsEqual(thisSession, payload.editorSession)) reloadDocument()
            // If demoted to viewer, show a modal
            else if (!isViewer() && !areSessionsEqual(thisSession, payload.editorSession))
                reloadDocument()
            // Now there is no editor
            else if (!payload.editorSession) documentStore.setEditorSession(null)
        })

        s.on(WebSocketEvents.USER_JOINED_DOCUMENT, (payload: UserJoinedDocumentPayload) => {
            menuBar.addUser(payload.user)
        })

        s.on(WebSocketEvents.USER_DISCONNECTED, (payload: UserDisconnectedPayload) => {
            menuBar.removeUser(payload.editorSession)
        })

        s.on(WebSocketEvents.TITLE_CHANGED, (payload: TitleChangedPayload) => {
            if (isPreviewMode()) return
            setTitle(payload.title)
        })

        // comments

        s.on(WebSocketEvents.COMMENT_ADDED, (payload: CommentAddedPayload) => {
            updateThreads(payload.updatedThread)
        })

        s.on(WebSocketEvents.COMMENT_UPDATED, (payload: CommentUpdatedPayload) => {
            updateThreads(payload.updatedThread)
        })

        s.on(WebSocketEvents.COMMENT_DELETED, (payload: CommentDeletedPayload) => {
            if (payload.isThreadDeleted) {
                removeThread(payload.deletedThreadId)
            } else {
                updateThreads(payload.updatedThread)
            }
        })

        s.on(WebSocketEvents.THREAD_DELETED, (payload: ThreadDeletedPayload) => {
            removeThread(payload.deletedThreadId)
        })

        s.on(WebSocketEvents.TRANSACTION_COMMITTED, (payload: WebSocketTransactionPayload) => {
            // if the user is editing then we don't worry about receiving other updates
            if (!isPreviewMode() || isViewingHistory()) return

            const { operations } = payload

            transactionObserver.emitTransaction(operations)
        })
    }

    useEffect(() => {
        if (!isPublicRoute && !isAuthenticated) {
            navigate('/login')
            return
        }

        // currently we pass null if a user is not logged in and
        // anonymous viewing is allowed
        ;(async () => {
            const token = (await api.getAccessTokenSilently()) || null
            const s = createWebSocket(token)

            initSocket(s)
            loadDocument(s)
            setSocket(s)
        })()
    }, [documentId])

    const onTransaction = useCallback(
        (ops: Operation[], v: Descendant[]) => {
            if (!socket || isPreviewMode()) return

            const payload: WebSocketTransactionPayload = {
                type: WebSocketEvents.COMMIT_TRANSACTION,
                operations: ops,
            }

            socket.emit(WebSocketEvents.COMMIT_TRANSACTION, payload)

            setNodeIdOrder(getIdOrderFromDescendents(v))
        },
        [socket, selectedRestorePoint, documentStore.value.editorSession, userId]
    )

    useEffect(() => {
        if (!socket) return () => {}

        socket.removeAllListeners()
        initSocket(socket)

        return () => {
            socket.removeAllListeners()
        }
    }, [documentStore.value.editorSession, documentStore.setEditorSession, onTransaction])

    const reloadDocument = () => {
        if (!socket) return
        loadDocument(socket, true)
    }

    const loadDocument = (s: Socket, loadAsViewer = false) => {
        s.emit(WebSocketEvents.LOAD, {
            type: WebSocketEvents.LOAD,
            sessionId: documentStore.value.localSessionId,
            loadAsViewer,
            documentId,
        })
    }

    const enterEditMode = () => {
        if (!socket) return

        setIsChangingMode(true)
        if (isViewingHistory()) {
            setSelectedRestorePoint(null)
            setIsHistoryOpen(false)
            reloadDocument()
            return
        }
        socket.emit(WebSocketEvents.REQUEST_EDIT_ACCESS)
    }

    const enterViewingMode = () => {
        documentStore.setEditorSession(null)
        reloadDocument()

        // Update value so there's no jumping between when we go into view mode, but before reload
        setEditorValue((val) => {
            if (!editorRef.current?.children?.length) return val
            return {
                ...val,
                nodes: editorRef.current.children,
            } as ShapeEditorValue
        })

        setSelectedRestorePoint(null)
        setIsHistoryOpen(false)

        if (!socket) return
        socket.emit(WebSocketEvents.ENTER_VIEW_MODE)
    }

    const getDocumentData = async () => {
        if (documentId) {
            try {
                const document = await api.getDocument(documentId)
                setIsStarred(document.isStarred)
                setDocumentMetadata(document)

                setThreads(document.threads)
            } catch (e) {
                if (!isAuthenticated) {
                    navigate('/login')
                } else {
                    navigate('/projects')
                }
            }
        }
    }

    const getFolder = async () => {
        if (isReviewDocument(documentMetadata) || !documentMetadata?.parent || isPublicView) {
            setFolder(null)
            return
        }

        try {
            const res = await api.getFolder(documentMetadata?.parent)

            if (!res.success) {
                throw new Error('Folder not found')
            }

            setFolder(res.result)
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        getDocumentData()
    }, [documentId])

    useEffect(() => {
        getFolder()
    }, [documentMetadata])

    useEffect(
        () => () => {
            socket?.close()
        },
        [socket]
    )

    const onImageSelected = (uploadId: string) => {
        setIsImageModalOpen(false)

        if (!editorRef.current) {
            return
        }

        insertImage(editorRef.current as any, uploadId)
    }

    const setSelectedModel = (
        selectedModelId: ModelId,
        selectedRepoId: RepositoryId,
        modelPathData: ModelPathData,
        selectedCommit: ModelCommit | undefined,
        options?: OpenWizardOptions
    ) => {
        if (!editorRef.current) {
            return
        }
        // If there is document metadata then is a review doc so
        // we get the correspondant model version based on that commit

        // If there is not metadata then we're dealing with a knowledge doc and so based
        //  on the value of selectedCommit we chose between freezing or autosyncing it
        let modelCommit
        if (selectedCommit && !isReviewDocument(documentMetadata)) {
            modelCommit = selectedCommit
        } else if (isReviewDocument(documentMetadata)) {
            modelCommit = {
                sha: documentMetadata.commitHash,
                message: '',
            }
        }

        if (options?.isNew) {
            insertModelViewer(
                editorRef.current as any,
                selectedRepoId,
                selectedModelId,
                modelPathData,
                modelCommit
            )
        } else {
            setNodes(
                editorRef.current as any,
                {
                    type: BlockType.MODEL_VIEWER,
                    value: {
                        repoId: selectedRepoId,
                        modelId: selectedModelId,
                        modelCommit,
                        modelPathData,
                    },
                },
                options?.at
            )
        }
    }

    const onSelectModel = () => {
        setIsModelModalOpen(true)
    }

    const onSelectImage = () => {
        setIsImageModalOpen(true)
    }

    const onOpenWizard = (blockType: BlockType, options: OpenWizardOptions = {}) => {
        setWizardOptions(options)
        switch (blockType) {
            case BlockType.MODEL_VIEWER:
                onSelectModel()
                break
            case BlockType.IMAGE:
                onSelectImage()
                break
            default:
                throw new Error(`Cannot open wizard for block ${blockType}`)
        }
    }

    const isViewingHistory = () => !!selectedRestorePoint
    const isViewer = () =>
        isPublicView ||
        documentStore.value.editorSession?.userId !== userId ||
        documentStore.value.editorSession?.sessionId !== documentStore.value.localSessionId
    const getDocumentMode = () => {
        if (isViewer()) return DocumentMode.REVIEWING
        return DocumentMode.EDITING
    }

    const isPreviewMode = () => isViewer()
    const canRestore = !isViewer()

    if (!editorValue?.nodes) {
        return null
    }

    const shouldShowBlockDrawer = Boolean(
        !isPublicView &&
            // if public or is in review mode on a non-review document hide the drawer
            isReviewDocument(documentMetadata)
    )

    return (
        <>
            <div onMouseDown={(e) => e.stopPropagation()}>
                <SelectModelModal
                    isReviewDocument={isReviewDocument(documentMetadata)}
                    forcedBranch={isReviewDocument(documentMetadata) ? documentMetadata.branch : ''}
                    forcedRepositoryId={
                        isReviewDocument(documentMetadata) ? documentMetadata.repoId : ''
                    }
                    isModalOpen={isModelModalOpen}
                    onCloseModal={() => setIsModelModalOpen(false)}
                    onSelectModelId={(model, repo, path, selectedCommit) =>
                        setSelectedModel(model, repo, path, selectedCommit, wizardOptions)
                    }
                />
            </div>
            <SelectImageModal
                isModalOpen={isImageModalOpen}
                onCloseModal={() => setIsImageModalOpen(false)}
                onSelectImage={onImageSelected}
            />

            {isReviewDocument(documentMetadata) && documentMetadata.latestDocument && (
                <RequestDialog
                    isOpen={isNewDocumentModalOpen}
                    onClose={() => setIsNewDocumentModalOpen(false)}
                    repositoryId={documentMetadata.repoId}
                    branches={[
                        {
                            name: documentMetadata.branch,
                            headCommit: documentMetadata.latestDocument.headCommit,
                        },
                    ]}
                    teamId={documentMetadata.team}
                    branchHasOpenReviews={() => false}
                />
            )}

            <EditorWrapper
                ref={dropRef}
                $activeEditor={activeEditor}
                $isViewing={isViewer()}
                $withDrawer={shouldShowBlockDrawer}
            >
                <Helmet>
                    <title>{title ?? 'Untitled'}</title>
                </Helmet>
                <EditZoneContainer id="edit-zone-container">
                    <Portal>
                        <BannerStack />
                    </Portal>
                    <EditorInner id="editor-inner">
                        <ElementsContainer>
                            <TitleContainer>
                                <TitleInput
                                    disabled={isPreviewMode()}
                                    placeholder="Document Title"
                                    value={title || ''}
                                    onChange={(newTitle) => {
                                        saveTitleEvent(newTitle.replace(/(\r\n|\n|\r)/gm, ''))
                                    }}
                                />
                            </TitleContainer>
                            <DropBar ref={dropBarRef} />
                            <CommentButton
                                isOpenThread={!!openThreadId}
                                current={hoveredBlock}
                                add={addNewThread}
                                isPreviewMode={isPreviewMode()}
                            />
                            <ThreadManager
                                threads={threads}
                                openThreadId={openThreadId}
                                setOpenThreadId={handleOpenThread}
                                setEditingCommentId={setEditingCommentId}
                                editingCommentId={editingCommentId}
                                submitComment={submitComment}
                                editComment={editComment}
                                deleteComment={deleteComment}
                                deleteThread={deleteThread}
                                abandonComment={cancelAddNewThread}
                                nodeIdOrder={nodeIdOrder}
                                isDocumentInPreviewMode={isPreviewMode()}
                            />
                            {editorValue && documentMetadata?.team && (
                                <ShapeEditor
                                    authContext={authContext}
                                    value={editorValue}
                                    isPreviewMode={
                                        isPreviewMode() ||
                                        (isReviewDocument(documentMetadata) &&
                                            documentMetadata.isOutdated)
                                    }
                                    ref={editorRef}
                                    theme={LIGHT_THEME}
                                    teamId={documentMetadata.team}
                                    onTransactionFinished={onTransaction as any}
                                    onOpenWizard={onOpenWizard}
                                    onHover={setHoveredBlock}
                                    transactionObserver={transactionObserver}
                                    toolbarProps={{
                                        isHidden: isPublicView,
                                        isEditor: !isPreviewMode(),
                                        slotRight: (
                                            <ModeSelect
                                                isLoading={isChangingMode}
                                                mode={getDocumentMode()}
                                                onModeChanged={onDocumentModeChanged}
                                            />
                                        ),
                                        isDrawerOpen: shouldShowBlockDrawer,
                                    }}
                                    modelStore={modelStore}
                                />
                            )}
                        </ElementsContainer>
                    </EditorInner>
                </EditZoneContainer>
                {isHistoryOpen && editorValue && documentMetadata?.team && (
                    <HistoryOverlay
                        title={title}
                        teamId={documentMetadata.team}
                        editorValue={editorValue}
                        onClose={() => setIsHistoryOpen(false)}
                        restoreDocument={restoreDocument}
                        canRestore={canRestore}
                    />
                )}
                {shouldShowBlockDrawer && (
                    <Drawer
                        isOpen={isDrawerOpen}
                        setIsOpen={setIsDrawerOpen}
                        isPreviewMode={isPreviewMode()}
                        documentId={documentMetadata?.id}
                        reviews={
                            isReviewDocument(documentMetadata)
                                ? documentMetadata.reviews
                                : undefined
                        }
                        updateReviewData={getDocumentData}
                        documentMetadata={documentMetadata}
                        restoreDocument={restoreDocument}
                        reloadDocument={reloadDocument}
                    />
                )}
            </EditorWrapper>
        </>
    )
}

function getIdOrderFromDescendents(descendents: any[]): string[] {
    const idOrder: string[] = []

    descendents.forEach((descendent) => {
        if (descendent.id) {
            idOrder.push(descendent.id)
        }

        if (descendent.children) {
            idOrder.push(...getIdOrderFromDescendents(descendent.children))
        }
    })

    return idOrder
}

export default Editor
